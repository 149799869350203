import React, { Fragment, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';
import CookieConsent from 'react-cookie-consent';
import BannerSection from '../containers/SaasModern/Banner';
import Navbar from '../containers/SaasModern/Navbar';
import WorkingProcessSection from '../containers/SaasModern/WorkingProcess';
import PricingSection from '../containers/SaasModern/Pricing';
import PartnerSection from '../containers/SaasModern/Partner';
import FaqSection from '../containers/SaasModern/Faq';
import TrialSection from '../containers/SaasModern/Trial';
import InfoSection from '../containers/SaasModern/Info';
import FeatureSection from '../containers/SaasModern/Feature';
import UpdateScreen from '../containers/SaasModern/UpdateScreen';
import TestimonialSection from '../containers/SaasModern/Testimonial';
import Footer from '../containers/SaasModern/Footer';
import SEO from '../components/seo';
import Tracking from '../components/tracking';
import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://analytics.stackocean.io',
  siteId: 1,
});

export default () => {
  const { pushInstruction } = useMatomo();
  const handleOnClick = () => {
    pushInstruction('disabled', true);
  };

  return (
    <MatomoProvider value={instance}>
      <ThemeProvider theme={saasModernTheme}>
        <Fragment>
          <SEO title="Couchsurvey - Umfragen schnell und einfach erstellen" />
          <Tracking />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSection />
            <WorkingProcessSection />
            <InfoSection />
            <FeatureSection />
            <UpdateScreen />
            <PricingSection />
            {/* <PartnerSection />
          <TestimonialSection /> */}
            <FaqSection />
            <TrialSection />
            <CookieConsent
              style={{
                background: '#000000',
              }}
              buttonText="Erlauben"
              buttonStyle={{
                borderRadius: '4px',
              }}
              enableDeclineButton
              declineButtonText="Verbieten"
              declineButtonStyle={{
                borderRadius: '4px',
              }}
              onDecline={() => {
                handleOnClick();
              }}
            >
              Wir verwenden Cookies, um den Betrieb der Webseite zu ermöglichen.
              Nähere Informationen findest Du in unserer Datenschutzerklärung.{' '}
              <a
                href="https://app.couchsurvey.com/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                style={{ color: 'white', textDecoration: 'underline' }}
              >
                Mehr erfahren
              </a>
            </CookieConsent>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </MatomoProvider>
  );
};
